import ReactSelect from "react-select";
import { useSetRecoilState } from "recoil";
import { selectedDepartmentObject } from "../../providers";

const DepartmentSelect = ({
  setDepartSelectValue,
  setDepartment,
  examSelectValue,
  loading,
  departSelectValue,
  allExamDepartment,
}) => {
  const setSelectedDepartmentObject = useSetRecoilState(
    selectedDepartmentObject
  );

  const getDepartmentOptions = () => {
    const options = [];
    allExamDepartment.forEach((depart) =>
      options.push({
        label: depart.title.toString(),
        value: depart?.code + "/" + depart?._id + "/" + depart?.title,
      })
    );
    return options;
  };

  const onDepartmentSelect = (e) => {
    const extractedDepart = allExamDepartment.find(
      (depart) => depart._id === e.value.split("/")[1]
    );

    setSelectedDepartmentObject(extractedDepart);
    setDepartSelectValue(e);
    setDepartment(e.value);
  };

  return (
    <>
      <h6 className="mb-1"> Select Department</h6>
      <ReactSelect
        onChange={onDepartmentSelect}
        isDisabled={!examSelectValue}
        placeholder="Select Exam"
        className="basic-single"
        classNamePrefix="select"
        isLoading={loading}
        value={departSelectValue}
        isSearchable
        options={getDepartmentOptions()}
      />
    </>
  );
};

export default DepartmentSelect;
