import React, { useCallback, useEffect, useMemo } from "react";
import { Input, Select } from "../../components/inputs";
import { ALL_PRODUCT_MODES_ARRAY } from "../../constants/Common";
import ReactDatePicker from "react-datepicker";
import { BsFillCalendar2Fill } from "react-icons/bs";
import { Button } from "../../components/buttons";
import ExamSelect from "../../components/AutoComplete/exam-select";
import { useRecoilValue } from "recoil";
import { allExams, authUser, set } from "../../providers";
import { instance } from "../../services/https/inceptors";
import { endpoints } from "../../constants/Endpoints";
import ReactSelect from "react-select";
import { showToast } from "../../utilities/toast";
import { useHistory, useParams } from "react-router-dom";
import ToggleButton from "../../components/toggle-button";

const UpdateReferralCode = () => {
  const [title, setTitle] = React.useState("");
  const [code, setCode] = React.useState("");
  const [product, setProduct] = React.useState("");
  const [discount, setDiscount] = React.useState("");
  const [expiryDate, setExpiryDate] = React.useState(null);
  const [status, setStatus] = React.useState(false);

  const recoilAuth = useRecoilValue(authUser);
  const allExamsData = useRecoilValue(allExams);
  const [selectedExam, setSelectedExam] = React.useState(null);

  const [allExamDepartmentData, setAllDepartmentsData] = React.useState([]);
  const [selectedDepartment, setSelectedDepartment] = React.useState(null);

  const { id: referralCodeId } = useParams();
  const { goBack } = useHistory();

  const onSelectExam = useCallback(
    async (exam) => {
      setSelectedDepartment([]);
      if (exam) {
        const departs = await instance.get(
          endpoints.getAllowedDepartments +
            `?exam=${
              exam.split("/")[1]
            }&departmentPermissions=${recoilAuth.departmentPermissions.toString()}`
        );
        setAllDepartmentsData(departs.data);
        return departs.data;
      }
    },
    [recoilAuth.departmentPermissions]
  );

  const setPreviousData = useCallback(
    async (data) => {
      setTitle(data?.title);
      setCode(data.code);
      setProduct(data.product);
      setDiscount(data.discount);
      setExpiryDate(new Date(data.expiryDate));
      setStatus(data.status);

      const exam = allExamsData?.find((exam) => exam._id === data.exam);
      const value = exam?.code + "/" + exam?._id + "/" + exam?.title;

      setSelectedExam({
        value,
        label: exam.title,
      });

      const allExamDepartments = await onSelectExam(value);
      const referralCodeDepartments = data?.departments ?? [];

      const departments = referralCodeDepartments?.map((depart) => {
        const dep = allExamDepartments.find((dep) => dep._id === depart);
        if (dep) return { label: dep?.title, value: dep?._id };
        return false;
      });

      setSelectedDepartment(departments.filter(Boolean));
    },
    [allExamsData, onSelectExam]
  );

  const getReferralCodeById = useCallback(
    async (id) => {
      const { data } = await instance.get(`${endpoints.referralCode}/${id}`);
      if (data) {
        setPreviousData(data);
      }
    },
    [setPreviousData]
  );

  useEffect(() => {
    getReferralCodeById(referralCodeId);
  }, [getReferralCodeById, referralCodeId]);

  const onSubmitReferralCode = useCallback(
    async (e) => {
      e.preventDefault();

      if (code?.length < 6) {
        showToast({
          type: "error",
          message: "Referral code should be at least 6 characters",
        });

        return;
      }

      const selectedExamId = selectedExam?.value.split("/")[1];
      const payload = {
        code,
        title,
        status,
        product,
        discount,
        expiryDate: expiryDate.setHours(23, 59, 59, 999),
        exam: selectedExamId,
        departments: selectedDepartment.map((depart) => depart.value),
      };

      const { data } = await instance.put(
        `${endpoints.referralCode}/${referralCodeId}`,
        payload
      );

      if (data) {
        showToast({
          type: "success",
          message: "Referral code updated successfully",
        });

        goBack();
      }
    },
    [
      code,
      selectedExam?.value,
      title,
      status,
      product,
      discount,
      expiryDate,
      selectedDepartment,
      referralCodeId,
      goBack,
    ]
  );

  const getDepartmentOptions = useCallback(() => {
    const options = [];
    allExamDepartmentData.forEach((depart) =>
      options.push({
        label: depart.title.toString(),
        value: depart?._id,
      })
    );
    return options;
  }, [allExamDepartmentData]);

  const shouldDisableSubmit = useMemo(() => {
    return (
      !title ||
      !code ||
      !product ||
      !expiryDate ||
      !selectedExam ||
      !selectedDepartment ||
      discount === undefined
    );
  }, [
    discount,
    expiryDate,
    product,
    code,
    selectedDepartment,
    selectedExam,
    title,
  ]);

  return (
    <div className="container flex flex-col items-center justify-center ">
      <h2 className="space-heading">Update Referral Codes</h2>

      <form onSubmit={onSubmitReferralCode} className="w-1/2">
        <div className="flex justify-end">
          <ToggleButton
            className="mt-5 mb-2"
            label=""
            onChange={setStatus}
            value={status}
          />
        </div>

        <Input label="Title" value={title} onChange={setTitle} />
        <Input label="Referral Code" value={code} onChange={setCode} />

        <Select label="Mode" value={product} onChange={setProduct}>
          {ALL_PRODUCT_MODES_ARRAY.map((key) => (
            <option value={key}> {key.split("_").join(" ")} </option>
          ))}
        </Select>

        <ExamSelect
          allExams={allExamsData}
          examSelectValue={selectedExam}
          selectExam={onSelectExam}
          setExamSelectValue={setSelectedExam}
          setAllDepartments={setAllDepartmentsData}
          setDepartSelectValue={setSelectedDepartment}
          loading={false}
        />

        <div className="mt-4">
          <h6 className="mb-1">Select Department</h6>
          <ReactSelect
            isMulti
            hideSelectedOptions
            filterOption={(option, searchText) => {
              return option.data.label
                .toLowerCase()
                .includes(searchText.toLowerCase());
            }}
            onChange={(e) => {
              setSelectedDepartment(e);
            }}
            placeholder="Select Exam"
            className="basic-single"
            classNamePrefix="select"
            value={selectedDepartment}
            isSearchable
            options={getDepartmentOptions()}
          />
        </div>

        <Input
          className="mt-4"
          label="Discount"
          placeholder="Enter Discount in %"
          type="number"
          value={discount}
          onChange={(value) => {
            if (value <= 99) {
              setDiscount(value);
            }
          }}
        />

        <div className="mt-4">
          <div className="mb-2">Expiry Date</div>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <div>
              <ReactDatePicker
                fixedHeight
                customInput={
                  <BsFillCalendar2Fill
                    cursor="pointer"
                    size={26}
                    color="green"
                  />
                }
                selected={expiryDate}
                minDate={Date.now()}
                onChange={(date) => setExpiryDate(date)}
              />
            </div>

            <div
              style={{
                width: "100%",
                cursor: "not-allowed",
                border: "1px solid gainsboro",
                padding: "5px 22px",
                borderRadius: "4px",
              }}
            >
              {expiryDate?.toString().length
                ? expiryDate?.toString()?.slice(0, 15)
                : "Date not selected"}
            </div>
          </div>
        </div>

        <div className="text-center mb-5 mt-4">
          <Button
            type="submit"
            disabled={shouldDisableSubmit}
            className="w-25 mt-3"
          >
            Submits
          </Button>
        </div>
      </form>
    </div>
    // </div>
  );
};

export default UpdateReferralCode;
