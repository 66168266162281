import { useCallback, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillCalendar2Fill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button } from "../../components/buttons";
import { Input, Select } from "../../components/inputs";
import { DURATION_TYPE, ALL_PRODUCT_MODES_ARRAY } from "../../constants/Common";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";
import { CiCirclePlus } from "react-icons/ci";
import { FaRegTrashAlt } from "react-icons/fa";
import { PAYMENT_TYPE } from "../../constants/Payments";

import {
  department as recoilDepart,
  exam as recoilExam,
} from "../../providers/index";
import ToggleButton from "../../components/toggle-button";

const CreatePayment = () => {
  const { goBack } = useHistory();

  const [type, setType] = useState("");
  const [mode, setMode] = useState("");
  const [title, setTitle] = useState("");
  const [discount, setDiscount] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [description, setDescription] = useState("");
  const [actualPrice, setActualPrice] = useState("");
  const [allowReferral, setAllowReferral] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);

  const department = useRecoilValue(recoilDepart).split("/")[1];
  const exam = useRecoilValue(recoilExam).split("/")[1];

  useEffect(() => {
    if (type === PAYMENT_TYPE.SUBSCRIPTION) {
      setActualPrice(undefined);
      setExpiryDate(undefined);
    } else if (type === PAYMENT_TYPE.EXPIRY) {
      setSubscriptions([]);
    }
  }, [type]);

  const createPayment = async () => {
    try {
      if (type === PAYMENT_TYPE.SUBSCRIPTION && !subscriptions.length) {
        showToast({
          type: "error",
          message: "Add Atleast one subscription",
        });
        return;
      }

      if (type === PAYMENT_TYPE.SUBSCRIPTION && subscriptions.length) {
        const isMissing = subscriptions.some(
          (subscript) =>
            !subscript?.duration ||
            !subscript?.durationType ||
            !subscript?.actualPrice ||
            !subscript.title
        );

        if (isMissing) {
          showToast({
            type: "error",
            message: "Fields are missong while adding subscription!",
          });
          return;
        }
      }

      if (type === PAYMENT_TYPE.EXPIRY && (!actualPrice || !expiryDate)) {
        showToast({
          type: "error",
          message: "Field are missing for test series year payment!",
        });
        return;
      }

      const body = {
        mode,
        type,
        exam,
        title,
        discount,
        department,
        description,
        actualPrice,
        subscriptions,
        allowReferral,
        expiryDate: expiryDate?.setHours(23, 59, 59, 999),
      };

      await instance.post(`${endpoints.PAYMENT}/add`, body);
      goBack();
      showToast({ type: "success", message: "Successfully Saved Payment" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const addSubscriptionInput = useCallback(() => {
    if (subscriptions.length > 3) return;

    setSubscriptions((prev) => {
      const clone = [...prev];
      clone.push({
        id: clone.length,
        duration: 0,
        durationType: DURATION_TYPE.MONTH,
        actualPrice: 0,
        title: "",
      });
      return clone;
    });
  }, [subscriptions.length]);

  const onDurationChange = useCallback(
    (value, id) => {
      if (isNaN(value)) return;

      const clone = [...subscriptions];
      const index = clone.findIndex((e) => e.id === id);
      if (index !== -1) {
        clone[index].duration = Number(value);
      }
      setSubscriptions(clone);
    },
    [subscriptions]
  );

  const onDurationTypeChange = useCallback(
    (value, id) => {
      const clone = [...subscriptions];
      const index = clone.findIndex((e) => e.id === id);
      if (index !== -1) {
        clone[index].durationType = value;
      }
      setSubscriptions(clone);
    },
    [subscriptions]
  );

  const onPriceChange = useCallback(
    (value, id) => {
      if (isNaN(value)) return;

      const clone = [...subscriptions];
      const index = clone.findIndex((e) => e.id === id);
      if (index !== -1) {
        clone[index].actualPrice = Number(value);
      }
      setSubscriptions(clone);
    },
    [subscriptions]
  );

  const deleteSusbscriptionInput = useCallback(
    (id) => {
      setSubscriptions((prev) =>
        prev.filter((subscription) => subscription.id !== id)
      );
    },
    [setSubscriptions]
  );

  const onLabelChange = useCallback(
    (value, id) => {
      const clone = [...subscriptions];
      const index = clone.findIndex((e) => e.id === id);
      if (index !== -1) {
        clone[index].title = value;
      }
      setSubscriptions(clone);
    },
    [subscriptions]
  );

  return (
    <div>
      <h2 className="space-heading text-center">Create Payment</h2>
      <div className="w-50 m-auto">
        <Input
          className="mt-5"
          label="Title"
          value={title}
          onChange={setTitle}
        />

        <div className="mt-4 mb-4">
          <h6>Description</h6>
          <textarea
            rows="5"
            className="w-100 p-2"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <Select label="Mode" value={mode} onChange={setMode}>
          {ALL_PRODUCT_MODES_ARRAY.map((key) => (
            <option value={key}> {key.split("_").join(" ")} </option>
          ))}
        </Select>

        <Select label="Payment Type" value={type} onChange={setType}>
          {Object.values(PAYMENT_TYPE).map((key) => (
            <option className="!capitalize" value={key}>
              {key.split("_").join(" ")}{" "}
            </option>
          ))}
        </Select>

        {type === PAYMENT_TYPE.EXPIRY ? (
          <>
            <div className="mt-4">
              <div className="mb-2">Expiry Date</div>
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <div>
                  <ReactDatePicker
                    fixedHeight
                    customInput={
                      <BsFillCalendar2Fill
                        cursor="pointer"
                        size={26}
                        color="green"
                      />
                    }
                    selected={expiryDate}
                    minDate={Date.now()}
                    onChange={(date) => setExpiryDate(date)}
                  />
                </div>

                <div
                  style={{
                    width: "100%",
                    cursor: "not-allowed",
                    border: "1px solid gainsboro",
                    padding: "5px 22px",
                    borderRadius: "4px",
                  }}
                >
                  {expiryDate?.toString().length
                    ? expiryDate?.toString()?.slice(0, 15)
                    : "Date not selected"}
                </div>
              </div>
            </div>

            <Input
              className="mt-4"
              label="Actual Price"
              type="number"
              placeholder="Price"
              value={actualPrice}
              onChange={setActualPrice}
            />
          </>
        ) : null}

        {type === PAYMENT_TYPE.SUBSCRIPTION ? (
          <>
            <div className="flex items-center gap-2 !mt-7">
              <h5>Subscription</h5>
              <CiCirclePlus
                onClick={addSubscriptionInput}
                className="cursor-pointer -top-1 !text-primary relative"
                size={24}
              />
            </div>
            {subscriptions.map(
              ({ duration, durationType, id, actualPrice, title }) => {
                return (
                  <div className="flex gap-3 items-center">
                    <Input
                      type="text"
                      value={title}
                      onChange={(value) => onLabelChange(value, id)}
                      placeholder="Enter Title"
                      label="Title"
                    />

                    <Input
                      className="w-10"
                      type="text"
                      value={duration}
                      onChange={(value) => onDurationChange(value, id)}
                      placeholder={durationType}
                      label={durationType}
                    />

                    <Select
                      label="Duration Type"
                      value={durationType}
                      onChange={(value) => onDurationTypeChange(value, id)}
                    >
                      {Object.keys(DURATION_TYPE).map((key) => (
                        <option value={key}>
                          {" "}
                          {key.split("_").join(" ")}{" "}
                        </option>
                      ))}
                    </Select>

                    <Input
                      className="w-28"
                      type="text"
                      value={actualPrice}
                      onChange={(value) => onPriceChange(value, id)}
                      placeholder="Price in INR"
                      label="Price"
                    />

                    <FaRegTrashAlt
                      onClick={() => deleteSusbscriptionInput(id)}
                      className="relative top-1 cursor-pointer"
                    />
                  </div>
                );
              }
            )}
          </>
        ) : null}

        <Input
          className="mt-4"
          label="Discount"
          placeholder="Enter Discount in %"
          type="number"
          value={discount}
          onChange={(value) => {
            if (value <= 99) {
              setDiscount(value);
            }
          }}
        />

        <div className="flex justify-end">
          <ToggleButton
            className="mt-3"
            label="Allow Referral"
            value={allowReferral}
            onChange={setAllowReferral}
          />
        </div>
      </div>

      <div className="text-center mb-5 mt-4">
        <Button
          disabled={!title || !description || !mode}
          onClick={createPayment}
          className="w-25 mt-3"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default CreatePayment;
