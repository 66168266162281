import React, { useCallback, useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import { Button } from "../../components/buttons";
import { useHistory } from "react-router-dom";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import dayjs from "dayjs";
import { convertEnumToString } from "../../utilities/helpers";

const Referrals = () => {
  const { push } = useHistory();
  const [referralCodeData, setReferralCodeData] = useState({});

  const getAllReferralCodes = useCallback(async () => {
    const { data } = await instance.get(endpoints.referralCode);
    if (data) setReferralCodeData(data);
  }, []);

  useEffect(() => {
    getAllReferralCodes();
  }, [getAllReferralCodes]);

  const deleteReferralCode = useCallback((id) => {}, []);

  return (
    <div className="text-center container ">
      <h2 className="space-heading text-center mb-5">Referrals Codes</h2>

      {referralCodeData?.length ? (
        <table id="reviews">
          <tr>
            <th>Title</th>
            <th>Product</th>
            <th>Expiry Date</th>
            <th>Discount</th>
            <th>Enabled</th>
            <th>Actions</th>
          </tr>

          {referralCodeData?.map((item) => (
            <tr>
              <td>{item?.title}</td>
              <td>{convertEnumToString(item?.product)}</td>
              <td>{dayjs(item.expiryDate).format("DD/MM/YYYY") ?? "N/A"}</td>
              <td>{item.discount ?? 0}%</td>
              <td>{item.status ? "Yes" : "No"}</td>

              <td className="text-center">
                <AiFillDelete
                  onClick={() => deleteReferralCode(item._id)}
                  className="c-pointer"
                  size={20}
                />
                <MdEdit
                  onClick={() => push(`/update-referral-code/${item._id}`)}
                  className="c-pointer"
                  size={20}
                />
              </td>
            </tr>
          ))}
        </table>
      ) : null}

      <Button
        onClick={() => push("/create-referral-code")}
        className="w-25 mt-5 mb-5"
      >
        Add New
      </Button>
    </div>
  );
};

export default Referrals;
